import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",

    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    children: [
      {
        path: "settings",
        name: "Settings",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Settings.vue")
      },
      {
        path: "install",
        name: "Install",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Install.vue")
      },
      {
        path: "customize",
        name: "Customize",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Customize.vue")
      },
      {
        path: "analytics",
        name: "Analytics",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Analytics.vue")
      }
    ]
  },

  {
    path: "/privacy-policy",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/PrivacyPolicy.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue")
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;
